import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import avatar from '../assets/images/avatar.png';

const Header = (props) => (
  <header id="header" className="alt">
    <Link to="/" className="logo">
      <img src={avatar} alt="avatar" />
      <strong>adina cianciotto</strong>
    </Link>
    <nav>
      <a
        aria-label="Open navigation"
        className="menu-link"
        onClick={props.onToggleMenu}
        href="#"
      />
      <ul className="links navigationLinks">
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        {/* <li>
          <Link to="/landing">Projects</Link>
        </li> */}
        {/* <li>
          <Link to="/landing">Landing</Link>
        </li> */}
        {/* <li>
          <Link to="/generic">Generic</Link>
        </li> */}
      </ul>
    </nav>
  </header>
);

Header.propTypes = {
  onToggleMenu: PropTypes.func,
};

export default Header;

// Looks good
