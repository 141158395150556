import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import profilePic from '../assets/images/about/profile.jpg';
import picAlt from '../assets/images/about/profile_alt.jpg';
import * as styles from '../assets/scss/modules/_about.module.scss';

const About = () => (
  <Layout>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>About</title>
      <meta name="description" content="About Me" />
    </Helmet>

    <div id="main">
      <section className={styles.about}>
        <div className="inner about-page">
          <header className="major">
            <h1>About Me</h1>
          </header>
          <div className={styles.container}>
            <div className={styles.text}>
              <h2>Hi, My name is Adina Cianciotto</h2>
              <p>
                I am a Software Developer and Northwestern graduate who fell in love with
                the art and science of coding. To me, development is a passion
                and joy. It's a platform to express creatively, never stop
                learning, and work together to bring bright ideas to life. Now I
                am driven by the passion to help others build accessible
                applications with meaningful, clean design, and help bring their
                ideas to life.
              </p>
              <p>
                When I'm not working, you can find me reading a good book,
                playing tennis, trying out fun recipes, or listening to some of
                my favorite podcasts. Currently, I am working at a tech company
                as a Front-End Developer.
              </p>
              <h4>Hobbies & New Tech</h4>
              <p>
                Like many people in the industry, learning technology is a hobby
                of mine. Some of the recent things I've been learning are Gatsby
                (Gatsby.js), which I used to build this website. It integrates
                React, Node.js, and GraphQL. I'm also always still looking to
                hone the skills I already have. Thanks to tech twitter for
                keeping me updated on some of the latest trends and
                technologies. Shout-out to some of my favorite podcasts like{' '}
                <a href="https://www.frontendhappyhour.com/"> @FrontEndHH,</a>
                &nbsp;&nbsp;
                <a href="https://syntax.fm/"> @syntaxfm,</a>&nbsp;&nbsp;
                <a href="https://softskills.audio/">@SoftSkillsEng </a>for
                always bringing laughs and talking about the best topics!
              </p>
              <p>
                I am also passionate about the environment and environmental
                awareness.
              </p>
              <h4>Contact Me</h4>
              <span>
                You can reach me by email at{' '}
                <a href="mailto: alcianciotto@gmail.com">
                  alcianciotto@gmail.com
                </a>{' '}
              </span>
            </div>
            <div className={styles.images}>
              <div className="imgContainer imgContainer--first">
                <img
                  className={styles.profile}
                  src={profilePic}
                  alt="Adina Cianciotto"
                />
              </div>
              <div className="imgContainer imgContainer--second">
                <img
                  src={picAlt}
                  className={styles.picture}
                  alt="Standing in Chicago at the Riverwalk"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default About;

// Looks good
