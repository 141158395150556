/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import Banner from '../components/Banner';
import * as styles from '../assets/scss/modules/_home.module.scss';

// Icons
import html5 from '../assets/images/icons/html5.png';
import css3 from '../assets/images/icons/css3.png';
import javascript from '../assets/images/icons/javascript.png';
import bigcommerce from '../assets/images/icons/bigcommerce.png';
import bootstrap from '../assets/images/icons/bootstrap.png';
import eslint from '../assets/images/icons/eslint.png';
import express from '../assets/images/icons/express.png';
import firebase from '../assets/images/icons/firebase.png';
import gatsby from '../assets/images/icons/gatsby.png';
import git from '../assets/images/icons/git.png';
import github from '../assets/images/icons/github.png';
import graphql from '../assets/images/icons/graphql.png';
import gulp from '../assets/images/icons/gulp.png';
import handlebars from '../assets/images/icons/handlebars.png';
import heroku from '../assets/images/icons/heroku.png';
import jquery from '../assets/images/icons/jquery.png';
import markdown from '../assets/images/icons/markdown.png';
import mongodb from '../assets/images/icons/mongodb.png';
import mysql from '../assets/images/icons/mysql.png';
import netlify from '../assets/images/icons/netlify.png';
import node from '../assets/images/icons/node.png';
import npm from '../assets/images/icons/npm.png';
import photoshop from '../assets/images/icons/photoshop.png';
import react from '../assets/images/icons/react.png';
import sass from '../assets/images/icons/sass.png';
import sql from '../assets/images/icons/sql.png';
import webpack from '../assets/images/icons/webpack.png';

// Projects
// optimize this img
import proj01 from '../assets/images/projects/asteroids.png';
import proj02 from '../assets/images/projects/fractal_tree.png';
import proj03 from '../assets/images/projects/clicky-game.webp';
import proj04 from '../assets/images/projects/smothii.webp';
import proj05 from '../assets/images/projects/pomodoro.png';
import proj06 from '../assets/images/projects/liri_bot.gif';

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          title="Adina Cianciotto : Website"
          meta={[
            {
              name: 'description',
              content:
                'This is the personal portfolio website of Adina Cianciotto. I am a Front-End Developer with a passion for technology and user-focused design. Stop by to read a little bit more about me and my work.',
            },
            {
              name: 'keywords',
              content:
                'Adina Cianciotto, Adina Lian Cianciotto, Adina Cianciotto Front-End Developer',
            },
            {
              name: 'author',
              content: 'Adina Cianciotto',
            },
          ]}
        />

        <Banner />

        <div id="main" className={styles.home}>
          <section>
            <div className="inner">
              <header className={`major ${styles.heading}`}>
                <h3>A bit about my work</h3>
                <h4>Here's a look at some of skills I possess</h4>
              </header>
              <ul className="icons technologies">
                <li>
                  <img alt="HTML5" src={html5} />
                </li>
                <li>
                  <img alt="CSS3" src={css3} />
                </li>
                <li>
                  <img alt="JavaScript" src={javascript} />
                </li>
                <li>
                  <img alt="React" src={react} />
                </li>
                <li>
                  <img alt="Sass" src={sass} />
                </li>
                <li>
                  <img alt="Node.js" src={node} />
                </li>
                <li>
                  <img alt="jQuery" src={jquery} />
                </li>
                <li>
                  <img alt="Gatsby" src={gatsby} />
                </li>
                <li>
                  <img alt="Webpack" src={webpack} />
                </li>
                <li>
                  <img alt="BigCommerce" src={bigcommerce} />
                </li>
                <li>
                  <img alt="GraphQL" src={graphql} />
                </li>
                <li>
                  <img alt="Bootstrap" src={bootstrap} />
                </li>
                <li>
                  <img alt="ESLint" src={eslint} />
                </li>
                <li>
                  <img alt="Handlebars" src={handlebars} />
                </li>
                <li>
                  <img alt="Firebase" src={firebase} />
                </li>
                <li>
                  <img alt="Git" src={git} />
                </li>
                <li>
                  <img alt="Gulp" src={gulp} />
                </li>
                <li>
                  <img alt="SQL" src={sql} />
                </li>
                <li>
                  <img alt="Express" src={express} />
                </li>
                <li>
                  <img alt="Heroku" src={heroku} />
                </li>
                <li>
                  <img alt="Github" src={github} />
                </li>
                <li>
                  <img alt="Markdown" src={markdown} />
                </li>
                <li>
                  <img alt="MongoDB" src={mongodb} />
                </li>
                <li>
                  <img alt="MySQL" src={mysql} />
                </li>
                <li>
                  <img alt="Netlify" src={netlify} />
                </li>
                <li>
                  <img alt="npm" src={npm} />
                </li>
                <li>
                  <img alt="Photoshop" src={photoshop} />
                </li>
              </ul>
            </div>
          </section>
          <section className="inner">
            <header className={`major ${styles.headingProjects}`}>
              <h3>Projects</h3>
            </header>
            <div className={`tiles ${styles.projects}`}>
              <article style={{ backgroundImage: `url(${proj05})` }}>
                <header className="major">
                  <h4>Pomodoro</h4>
                  <p>React Pomodoro Productivity Application</p>
                </header>
                <a
                  href="https://github.com/adi868/Pomodoro"
                  target="_blank"
                  className="link primary"
                  rel="noreferrer"
                  aria-label="Pomodoro React Pomodoro Productivity Application. This link opens in a new tab."
                />{' '}
              </article>
              <article style={{ backgroundImage: `url(${proj01})` }}>
                <header className="major">
                  <h4>Asteroids</h4>
                  <p>JavaScript Asteroids game built with p5.js</p>
                </header>
                <a
                  href="https://github.com/adi868/Asteroids-Game"
                  target="_blank"
                  className="link primary"
                  rel="noreferrer"
                  aria-label="Asteroids JavaScript Asteroids game built with p5.js. This link opens in a new tab."
                />{' '}
              </article>
              <article style={{ backgroundImage: `url(${proj02})` }}>
                <header className="major">
                  <h4>Fractal Trees</h4>
                  <p>Recursive generative art created with p5.js</p>
                </header>
                <a
                  href="https://github.com/adi868/Fractal-Trees"
                  target="_blank"
                  className="link primary"
                  rel="noreferrer"
                  aria-label="Fractal Trees recursive generative art created with p5.js. This link opens in a new tab."
                />{' '}
              </article>
              <article style={{ backgroundImage: `url(${proj03})` }}>
                <header className="major">
                  <h4>Card Memory Game</h4>
                  <p>Click-based memory game built with React.js</p>
                </header>
                <a
                  href="https://github.com/adi868/Clicky-Game"
                  target="_blank"
                  className="link primary"
                  rel="noreferrer"
                  aria-label="Card memory game click-based memory game built with React.js. This link opens in a new tab."
                />
              </article>
              <article style={{ backgroundImage: `url(${proj04})` }}>
                <header className="major">
                  <h4>Smöthii</h4>
                  <p>
                    Full-stack application for a fresh smoothing vending machine
                  </p>
                </header>
                <a
                  href="https://github.com/adi868/Smothii"
                  target="_blank"
                  className="link primary"
                  rel="noreferrer"
                  aria-label="Smöthii a full-stack application for a fresh smoothing vending
                    machine. This link opens in a new tab."
                />
              </article>
              <article style={{ backgroundImage: `url(${proj06})` }}>
                <header className="major">
                  <h4>LIRI-Bot</h4>
                  <p>Language Interpretation and Recognition Bot</p>
                </header>
                <a
                  href="https://github.com/adi868/LIRI-Bot"
                  target="_blank"
                  className="link primary"
                  rel="noreferrer"
                  aria-label="LIRI-Bot Language Interpretation and Recognition Bot. This link opens in a new tab."
                />
              </article>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default HomeIndex;

// Looks good
