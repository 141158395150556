import React from 'react';
import { Link } from 'gatsby';
import homePic from '../assets/images/home_img.png';
import * as styles from '../assets/scss/modules/_banner.module.scss';

const Banner = () => (
  <section id="banner" className={`major ${styles.banner}`}>
    <div className={`inner ${styles.container}`}>
      <div className={styles.content}>
        <h1>
          <span className={styles.hi}>Hi, </span>
          <span className={styles.im}>I'm </span>
          <span className={styles.adina}>Adina</span>
        </h1>
        <div className={styles.text}>
          <h2>I'm a Front-End Developer</h2>
          <h3>Welcome to my website</h3>
          <Link to="/about" className={`button special ${styles.button}`}>
            Read more about me here
          </Link>
        </div>
      </div>
      <div className={styles.imgContainer}>
        <img src={homePic} alt="woman working on a keyboard" />
      </div>
    </div>
  </section>
);

export default Banner;

// Looks good
