import React from 'react';
import accessibility from '../assets/images/accessibility.png';

const Footer = () => (
  <footer id="footer">
    <div className="inner">
      <ul className="icons">
        <li>
          <a aria-label="Github. This link opens in a new tab."
            href="https://github.com/adi868"
            target="_blank"
            className="icon alt fa-github"
            rel="noreferrer"
          >
          </a>
        </li>
        <li>
          <a aria-label="LinkedIn. This link opens in a new tab."
            href="https://www.linkedin.com/in/adina-cianciotto/"
            target="_blank"
            className="icon alt fa-linkedin"
            rel="noreferrer"
          >
          </a>
        </li>
        <li>
          <a aria-label="Email alcianciotto@gmail.com."
            href="mailto:alcianciotto@gmail.com"
            className="icon alt fa-envelope"
          >
          </a>
        </li>
      </ul>
      <div className="copyright">
        <span>
          Copyright &copy; {new Date().getFullYear()} Adina Cianciotto{' '}
          <a href="https://github.com/adi868" target="_blank" rel="noreferrer">
            adi868
          </a>
        </span>
      </div>
      <div className="accessibility">
        <span>
          This site has been built with accessibility in mind{' '}
          <img alt="accessibility symbol" src={accessibility} />
        </span>
      </div>
    </div>
  </footer>
);

export default Footer;

// Looks good
