import React from 'react';
import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout>
    <div id="main" className="alt">
      <section>
        <div className="inner">
          <h1>NOT FOUND</h1>
          <p>Sorry, the page was not found</p>
        </div>
      </section>
    </div>
  </Layout>
);

export default NotFoundPage;

// Looks good
